import React from "react"
import { graphql, Link } from "gatsby"

import Mailto from "react-protected-mailto"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/bio"

class AdvertisingPolicy extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Advertising Policy" />
        <div className="w-full">
          <div className="text-center px-6 py-12 mb-6 bg-gray-100 border-b">
              <h1 className="text-xl md:text-4xl pb-4">Advertising Policy</h1>
          </div>
          <div className="simple-post container max-w-5xl mx-auto py-8">
            <h2>Affiliate Partners</h2>
            <p>
              <em>Landing last minute</em> participates in a number of affiliate programs that change over time. We aim to
              provide our readers with the best options available to them, and to make it clear when these deals are
              being provided by a partner of ours.
            </p>
            <h2>Online Behavioural Advertising</h2>
            <p>
              Please refer to our <Link to="/privacy-policy">Privacy Policy</Link> for more information about
              online behavioural advertising.
            </p>
            <h2>Sponsored Posts</h2>
            <p>
              All sponsored posts are clearly marked ensuring our readers are clear on the relationships between
              author and sponsor.
            </p>
            <h2>Other</h2>
            <p>
              All posts are formed from the research and opinions of the author. No posts or content are edited by third
              parties.
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AdvertisingPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`